import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo, BreadcrumbJsonLd } from "gatsby-plugin-next-seo";
import Layout from "~/components/layout";
import PageHeading from "~/components/styled/page-heading";
import Image from "~/components/image";
import TourList from "~/components/tour-list";

const SpecialityPage = ({ data }) => {
	console.log(data);
	const tours = data.allStrapiTour.edges;
	const flatTours = tours.map(({ node }) => node);
	// const seo = {
	//   title: data.strapiSpeciality.name,
	// }

	return (
		<Layout>
			{/* <SEO seo={seo} /> */}
			<GatsbySeo
				title={`All ${data.strapiSpeciality.name}`}
				description={`Join our ${data.strapiSpeciality.name} and experience the adventure of a lifetime.`}
				// languageAlternates={[
				// 	{
				// 		hrefLang: "en-IN",
				// 		href: `https://www.artofbicycletrips.com/en-IN/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en-US",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "en",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// 	{
				// 		hrefLang: "x-default",
				// 		href: `https://www.artofbicycletrips.com/activities/${data.strapiSpeciality.slug}`,
				// 	},
				// ]}
			/>
			<BreadcrumbJsonLd
				itemListElements={[
					{
						position: 1,
						name: "Specialities",
						item: "https://artofbicycletrips.com/specialities",
					},
					{
						position: 2,
						name: `${data.strapiSpeciality.name}`,
					},
				]}
			/>
			{data.strapiSpeciality.image && (
				<div className="">
					<Image
						className="rounded shadow-md transform"
						image={data.strapiSpeciality.image}
						alt={data.strapiSpeciality.image.alternativeText}
						loading="eager"
					/>
				</div>
			)}
			<div>
				<div className="text-center">
					<PageHeading>{data.strapiSpeciality.name}</PageHeading>
				</div>
				<p className="leading-loose mb-20 font-light md:text-xl md:ml-32 md:mr-32">
					{data.strapiSpeciality.desc}
				</p>
			</div>
			<div>
				<h2 className="mb-10">Ease back into {data.strapiSpeciality.name}</h2>
				<TourList tours={flatTours} />
			</div>
		</Layout>
	);
};

export const query = graphql`
	query SpecialityQuery($slug: String!) {
		allStrapiTour(
			filter: { specialities: { elemMatch: { slug: { eq: $slug } } } }
			sort: { fields: price }
		) {
			edges {
				node {
					id
					duration
					price
					slug
					title
					featuredImage {
						localFile {
							publicURL
							childImageSharp {
								gatsbyImageData(
									layout: FULL_WIDTH
									placeholder: BLURRED
									aspectRatio: 1.3
								)
							}
						}
					}
					slider {
						id
						url
						name
						alternativeText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.3)
							}
						}
					}

					quickfacts {
						lodging
						level
					}
					activity {
						name
					}
					regions {
						name
						id
					}
					destination {
						name
					}
				}
			}
		}

		strapiSpeciality(slug: { eq: $slug }) {
			name
			desc
			slug
			image {
				alternativeText
				localFile {
					childImageSharp {
						gatsbyImageData(aspectRatio: 2.52, layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`;

export default SpecialityPage;
